import request from '../../utils/request';

/**
 * 贡献值标签管理
 * @param query
 * @returns {AxiosPromise}
 */

//上传地址
export const Actions = () => {
    return process.env.VUE_APP_URL+'Integral/Distbatch/batch/init'
};

//查询项目总数据
export const FindProjec = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Distbatch/TolObject/find',
        method: 'get',
        params: query
    });
};

//查询项目标签
export const ExportsType = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Distbatch/batch/exports/Type',
        method: 'get',
        params: query
    });
};

//导出项目的模板
export const BatchExports = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Distbatch/batch/exports',
        method: 'post',
        data: query
    });
};


//提交分配贡献值并申请审核
export const BatchAdd = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Distbatch/batch/add',
        method: 'post',
        data: query
    });
};

