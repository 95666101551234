<template>
  <el-dialog title="委员会额度" v-model="view" width="1000px">
    <el-table :data="tableData" height="500">
      <el-table-column prop="Title" label="社区项目昵称"></el-table-column>
      <el-table-column prop="ComName" label="委员会昵称"></el-table-column>
      <el-table-column prop="SetNumber" label="已使用额度"></el-table-column>
      <el-table-column prop="NoNumber" label="未使用额度"></el-table-column>
      <el-table-column prop="TolNumber" label="总额度"></el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="view = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {FindCommittee} from "../../../api/ProjectAdmin/Type";

export default {
  data() {
    return {
      tableData: [],
      view: false,
      query: {
        App_id: ''
      }
    }
  },
  methods: {
    open(App_id) {
      this.view = true
      this.query.App_id = App_id
      this.getdata()
    },
    getdata() {
      let App_id = this.query.App_id
      FindCommittee({App_id}).then(res => {
        this.tableData = res.sum
      })
    }
  }
}
</script>

<style scoped>

</style>