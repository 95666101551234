<template>

  <el-dialog title="导出模板设置" v-model="view" width="1000px">
    <el-table
        :data="tableData"
        border
        class="table"
        header-cell-class-name="table-header"
        height="400"
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="ComName" label="委员会"></el-table-column>
      <el-table-column prop="Name" label="分类昵称"></el-table-column>
      <el-table-column prop="Remarks" label="备注"></el-table-column>
      <el-table-column prop="Time" label="添加时间"></el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="view = false">取消</el-button>
        <el-button @click="exports">导出</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ExportsType,BatchExports} from '../../../api/Integral/Distbatch'

export default {
  data() {
    return {
      query: {
        App_id: '',
      },
      tableData: [],
      multipleSelection: [],
      view: false
    }
  },
  methods: {
    exports() {
      let NameData = []
      let multipleSelection = this.multipleSelection
      for (let item in multipleSelection) {
        NameData.push(multipleSelection[item].Name)
      }
      if (NameData.length>=1){
        BatchExports({NameData}).then(res=>{
          if (res.code==0){
            let file = res.file
            let files=process.env.VUE_APP_URL  + file
            window.open(files)
            this.$message.success(res.message);
            this.view=false
          }else {
            this.$message.error(res.message);
          }
        })
      }else {
        this.$message.error('未选中标签，请检查选择信息！');
      }
    },
    open(App_id) {
      this.tableData = []
      this.view = true
      this.query.App_id = App_id
      this.getdata()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getdata() {
      let App_id = this.query.App_id
      ExportsType({App_id}).then(res => {
        let sum = res.sum
        this.tableData = sum
      })
    }
  }
}
</script>

<style scoped>

</style>
