<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 贡献值批量分配
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <div class="mr5">
          <el-select v-model="query.address" placeholder="请选择项目" class="mr10" @change="handleSearch">
            <el-option
                v-for="(item,key) in DataObject"
                :key="key"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-button type="text" @click="openAmount()" class="reduce" v-if="query.App_id">查看委员会额度</el-button>
        </div>
        <el-button type="default" @click="exports" class="inFoExit" v-if="query.App_id">导出EXCEL模板</el-button>
        <el-upload
            v-if="query.App_id"
            :data="{App_id:query.App_id}"
            accept=".xlsx"
            :on-success="handleSuccess"
            :before-upload="inspect"
            :show-file-list="false"
            class="block"
            :action='actionUrl'>
          <el-button type="default" v-if="query.App_id">导入EXCEL</el-button>
        </el-upload>
      </div>
      <el-table
          :data="from.tableData"
          border
          v-if="query.App_id"
          height="600"
          class="table"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Name" label="姓名" width="180"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column label="分类贡献值" align="center">
          <template #default="scope">
            <el-button type="text" @click="openDetails(scope.row.TolType)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column label="总贡献值" align="center">
          <template #default="scope">
            {{ scope.row.ToNumber }}Q
          </template>
        </el-table-column>
      </el-table>
      <div class="handle-box" v-if="query.App_id">
        <div class="mr10 mrText" style="margin-right: 50px">总贡献值：{{ from.ToNumbers }}Q</div>
        <div class="mr10 mrText" style="margin-right: 50px">{{ from.tableData.length }}条数据</div>
        <el-button @click="AddForm">提交</el-button>
        <el-button @click="CloseForm">重置</el-button>
      </div>
    </div>
    <exportsType ref="exportsType"></exportsType>
    <DetailCommittee ref="DetailCommittee"></DetailCommittee>
  </div>
</template>

<script>
import {FindProjec, Actions, BatchAdd} from '../../api/Integral/Distbatch'
import exportsType from './com/exportsType'
import DetailCommittee from './com/DetailCommittee'

export default {
  data() {
    return {
      actionUrl: '',
      NumberObject: [],
      DataObject: [],
      query: {
        address: '',
        App_id: ''
      },
      from: {
        tableData: [],
        ToNumbers: 0
      }
    };
  },
  created() {
    this.actionUrl = Actions()
    FindProjec().then(res => {
      let sum = res.sum
      this.NumberObject = JSON.parse(JSON.stringify(sum))
      let DataObject = []
      for (let item in sum) {
        let Title = sum[item].Title
        let add = {
          label: Title,
          value: Title
        }
        DataObject.push(add)
      }
      this.DataObject = JSON.parse(JSON.stringify(DataObject))
    })
  },
  methods: {
    openAmount() {
      let App_id = this.query.App_id
      this.$refs.DetailCommittee.open(App_id)
    },
    openDetails(TypeData) {
      let data = ''
      for (let item in TypeData) {
        if (TypeData[item].Number >= 1) {
          data += '<p>' + TypeData[item].Type + '：           ' + TypeData[item].Number + ' QB</p>'
        }
      }
      this.$alert(data, {
        dangerouslyUseHTMLString: true
      });
    },
    inspect() {

    },
    exports() {
      let App_id = this.query.App_id
      if (App_id) {
        this.$refs.exportsType.open(App_id)
      } else {
        this.$message.error('未选中项目，请重试选择项目');
      }
    },
    handleSuccess(value) {
      this.from.tableData = value.initData
      this.from.ToNumbers = value.ToNumbers
    },
    AddForm() {
      let App_id = this.query.App_id
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id
      let initData = this.from.tableData
      let ToNumber = this.from.ToNumbers

      this.$prompt('请输入活动昵称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        let TitleName = value
        this.openLoading('处理中')
        BatchAdd({App_id, Admin_id, initData, ToNumber, TitleName}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.CloseForm()
          } else {
            this.$message.error(res.message);
          }
        })
      }).catch(() => {

      });
    },
    CloseForm() {
      this.from.ToNumbers = 0
      this.from.tableData = []
      this.query.address = ''
      this.query.App_id = ''
    },
    handleSearch(value) {
      let NumberObject = this.NumberObject
      for (let item in NumberObject) {
        if (value == NumberObject[item].Title) {
          let App_id = NumberObject[item].App_id
          this.query.App_id = App_id
          break;
        }
      }
    },
    openLoading(data) {
      if (!data) {
        data = '加载中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },
  },
  components: {
    exportsType,
    DetailCommittee
  }

};
</script>

<style scoped lang="scss">
.mr5 {
  margin-bottom: 20px;
}

.inFoExit {
  float: left;
  margin-right: 20px;
}

.table {
  font-size: 12px;
  margin-bottom: 20px;
}

.mrText {
  display: inline;
  color: #666;
  margin-right: 30px;
  font-size: 12px;
}

.reduce {
  margin-left: 20px;
}

::v-deep .el-upload--text {
  width: auto;
  height: auto;
}

</style>
